export const BASE_URL = 'https://socialcodia.pythonanywhere.com/api/';
// export const BASE_URL = 'http://localhost:8000/api/';
export const API_VERSION = 'v1';

//TOKEN
export const REFERESH_TOKEN = 'referesh';
export const ACCESS_TOKEN = 'access';

//USER
export const USER_ID = 'id';
export const USER_NAME = 'name';
export const USER_EMAIL = 'email';
export const USER_IMAGE = 'image';

//MEDICAL
export const MEDICAL_ID = 'id';
export const MEDICAL_NAME = 'name';
export const MEDICAL_IMAGE = 'image';

//Lables
export const LABLE_SEARCH_PRODUCTS = "Search Products";
export const LABLE_SEARCH_DISTRIBUTERS = "Search Distributers";
export const LABLE_NAME = "Enter Name";
export const LABLE_EMAIL = "Enter Email";
export const LABLE_PASSWORD = "Enter Password";
export const LABLE_PASSWORD_CONFIRM = "Enter Confirm Password";
export const LABLE_OTP = "Enter OTP";
export const LABLE_MOBILE = "Enter Mobile";
export const LABLE_ADDRESS = "Enter Address";


//Text
export const TEXT_ADD_DISTRIBUTER = "Add Distributer";
export const TEXT_SWR = "Oops..! Something went wrong";