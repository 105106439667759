import {all,fork} from 'redux-saga/effects';
import * as dashboardSaga from './dashboard-saga';
import * as productSaga from './product-saga';
import * as saleSaga from './sale-saga';
import * as distributerSaga from './distributer-saga';
import * as userSaga from './user-saga';

function* rootSaga(){
    yield all([
        ...Object.values(userSaga),
        ...Object.values(dashboardSaga),
        ...Object.values(productSaga),
        ...Object.values(saleSaga),
        ...Object.values(distributerSaga),

    ].map(fork)
    );
}

export default rootSaga;