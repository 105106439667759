import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTE_VERIFY } from "../../../routes";
import RegisterForm from "./components/register-form";


const RegisterPage = () =>{

  const {accessVerifyPage} = useSelector(state=>state.user);
  const navigate = useNavigate();

  const style = {
    position : 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
  }

  const cardStyle={
    p:4,
    boxShadow:4,
    borderRadius:4
  }
  

  console.log('accessVerifyPage',accessVerifyPage);

  useEffect(()=>{
    console.log('useEffect accessVerifyPage',accessVerifyPage);
    if(accessVerifyPage)
      navigate(ROUTE_VERIFY)
  },[accessVerifyPage])

  return(
    <Grid container sx={{...style}}>
      <Grid item sm={4}></Grid> 
      <Grid item sm={4}>
        <Card sx={{...cardStyle}} >
          <CardHeader sx={{pb:3}} title="Register" subheader="Register an account to continue..." />
          <RegisterForm/>
        </Card>
      </Grid> 
      <Grid item sm={4}></Grid> 
    </Grid>
  )
}

export default RegisterPage;