import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useSelector } from "react-redux";

const ProductsTable = () =>{

    const {isTodaysSalesLoading,todaysSales} = useSelector(state=>state.sale);

    return(
        <Grid container>
            <Grid item sm={12}>
            <Card sx={{mt:3}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Brand</TableCell>
                            <TableCell>Variant</TableCell>
                            <TableCell>Batch</TableCell>
                            <TableCell>Purchase Price</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Selling Price</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Selling Quantity</TableCell>
                            {/* <TableCell>Location</TableCell> */}
                            <TableCell>Expire</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    {isTodaysSalesLoading? 
                     <TableBody>
                        <TableRow>
                            <TableCell>
                                Loading
                            </TableCell>
                        </TableRow>
                     </TableBody> 
                     :
                    <TableBody>
                            {todaysSales.map((row,i)=>
                            <TableRow key={i}>
                                <TableCell>{i+1}</TableCell>
                                <TableCell>{row.product_stock.name}</TableCell>
                                <TableCell>{row.product_stock.category}</TableCell>
                                <TableCell>{row.product_stock.brand}</TableCell>
                                <TableCell>{row.product_stock.variant}</TableCell>
                                <TableCell>{row.product_stock.batch}</TableCell>
                                <TableCell>{row.product_stock.purchase_price}</TableCell>
                                <TableCell>{row.product_stock.price}</TableCell>
                                <TableCell>{row.price}</TableCell>
                                <TableCell>{row.discount}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                                {/* <TableCell>{row.location}</TableCell> */}
                                <TableCell>{row.product_stock.expire_date}</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>)
                        }
                    </TableBody>}
                </Table>
            </TableContainer>
        </Card>
            </Grid>
        </Grid>
    )
}

export default ProductsTable;