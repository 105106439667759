import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    expiredProducts : [],
    expiringProducts : [],
    products : [],
    isExpiredProductsLoading : true,
    isExpiringProductsLoading : true,
    isProductsLoading : true
}


const productSlice = createSlice({
    name:'product',
    initialState,
    reducers:{
        getProducts: (state)=>{
            state.isProductsLoading = true;
        },
        setProducts : (state,action) =>{
            state.products = action.payload;
            state.isProductsLoading = false;
        },
        getExpiredProducts : (state) =>{
            state.isExpiredProductsLoading = true;
        },
        setExpiredProducts : (state,action) =>{
            state.expiredProducts = action.payload;
            state.isExpiredProductsLoading  = false;
        },
        getExpiringProducts : (state) =>{
            state.isExpiringProductsLoading = true;
        },
        setExpiringProducts : (state,action) =>{
            state.expiringProducts = action.payload;
            state.isExpiringProductsLoading = false;
        }
    }
})


export const {getProducts,getExpiredProducts,getExpiringProducts,setProducts,setExpiredProducts,setExpiringProducts} = productSlice.actions;
export default productSlice.reducer;