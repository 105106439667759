import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getExpiredProducts, getProducts } from "../../../redux/slice/product-slice";
import ProductsTable from "./components/products-table";
import SearchComponent from "./components/search-component";


const SellProductPage = () =>{

    const diaptch = useDispatch();
    
    // useEffect(()=>{
    //     const handleEsc = (e) =>{
    //         console.log('close kar')
    //         if(e.keyCode==27){
    //             // setOpen(false);
    //             console.log('close kar')
    //         }
    //     }
    //     window.addEventListener('keydown',handleEsc);

    //     return () =>{
    //         window.removeEventListener('keydown',handleEsc);
    //     }
    // },[])

    useEffect(()=>{
        diaptch(getProducts())
    },[])

    return(
        <>
        <SearchComponent/>
        <ProductsTable/>
        </>
    )
}

export default SellProductPage;