const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    productStatus:{
        expired:0,
        expiring:0,
        products:0,
        notice:0,
    },
    isProductStatusLoading :true,
}

const dashboardSlice = createSlice({
    name:'dashboard',
    initialState,
    reducers:{
        getDashboardProductsStatus : (state)=>{
            state.isProductStatusLoading = true;
        },
        setDashboardProductsStatus : (state,action) =>{
            state.productStatus = action.payload;
            state.isProductStatusLoading = false;
        }
    }
});

export const {getDashboardProductsStatus,setDashboardProductsStatus} = dashboardSlice.actions;
export default dashboardSlice.reducer;