import { createSlice } from "@reduxjs/toolkit"

const errorState = {
    errorAddDistributer : {},
    errorDistributer : {},
    errorDistributers : {}
}

const initialState = {
    ...errorState,
    isOpen : false,
    isDistributersLoading : true,
    isDistributerLoading : true,
    isDistributerAdding:false,
    distributers : [],
    distributer : {}
}

const distributerSlice = createSlice({

    initialState,
    name:'distributer',
    reducers:{
        setIsOpen : (state,action) =>{
            state.isOpen = action.payload
        },
        getDistributer : (state) =>{
            state.errorDistributers = {}
            state.isDistributerLoading = true;
        },
        setDistributer : (state,action)=>{
            state.distributer = action.payload;
            state.isDistributerLoading = false;
        },
        errorDistributer: (state,action)=>{
            state.isDistributerLoading = false;
            state.errorDistributer = action.payload; 
        },
        getDistributers : (state)=>{
            state.errorDistributers = {}
            state.isDistributersLoading = true;
        },
        setDistributers : (state,action) =>{
            state.distributers = action.payload;
            state.isDistributersLoading = false
        },
        errorDistributers: (state,action)=>{
            state.isDistributersLoading = false;
            state.errorDistributers = action.payload; 
        },
        getAddDistributer: (state,action)=>{
            state.isDistributerAdding = true;
            state.errorAddDistributer = {};
        },
        setAddDistributer : (state,action)=>{
            const payload = action.payload;
            state.isDistributerAdding = false;
            state.isOpen = false;
            state.distributers  = [...state.distributers,payload];
        },
        errorAddDistributer:(state,action)=>{
            state.isDistributerAdding = false;
            state.errorAddDistributer = action.payload
        }
    }

});

export const {setIsOpen,getDistributer,setDistributer,getDistributers,setDistributers,getAddDistributer,setAddDistributer,errorAddDistributer,errorDistributer,errorDistributers} = distributerSlice.actions;
export default distributerSlice.reducer;