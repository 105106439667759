import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getExpiredProducts } from "../../../redux/slice/product-slice";
import ProductsTable from "./components/products-table";
import SearchComponent from "./components/search-component";


const ProductExpiredPage = () =>{

    const diaptch = useDispatch();

    useEffect(()=>{
        diaptch(getExpiredProducts())
    },[])

    return(
        <>
        <SearchComponent/>
        <ProductsTable/>
        </>
    )
}

export default ProductExpiredPage;