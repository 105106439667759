import * as Constants from '../utils/constants';

//Authentication and Authorization
export const ENDPOINT_REGISTER =  Constants.API_VERSION+'/auth/register/';
export const ENDPOINT_VERIFY =  Constants.API_VERSION+'/auth/verify/';
export const ENDPOINT_LOGIN = Constants.API_VERSION+'/auth/login/';
export const ENDPOINT_FORGOT = Constants.API_VERSION+'/auth/forgot/'
export const ENDPOINT_RESEND = Constants.API_VERSION+'/auth/resend/'
export const ENDPOINT_RESET = Constants.API_VERSION+'/auth/reset/'
export const ENDPOINT_LOGOUT = Constants.API_VERSION+'/auth/logout/'
export const ENDPOINT_REFERESH = Constants.API_VERSION+'/auth/referesh/'

//Status
export const ENDPOINT_PRODUCTS_STATUS = Constants.API_VERSION+'/status/products/';

//Products
export const ENDPOINT_PRODUCTS = Constants.API_VERSION+'/product_stocks/';
export const ENDPOINT_PRODUCTS_EXPIRING = Constants.API_VERSION+'/product_stocks/expiring/';
export const ENDPOINT_PRODUCTS_EXPIRED = Constants.API_VERSION+'/product_stocks/expired/';
export const ENDPOINT_PRODUCTS_NOTICE = Constants.API_VERSION+'/product_stocks/notice/';

//Sale
export const ENDPOINT_SALES = Constants.API_VERSION+'/sales/';

//Distributers
export const ENDPOINT_ADD_DISTRIBUTER = Constants.API_VERSION+'/distributers/';
export const ENDPOINT_DISTRIBUTER = Constants.API_VERSION+'/distributers/';
export const ENDPOINT_DISTRIBUTERS = Constants.API_VERSION+'/distributers/';