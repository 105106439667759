import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SocialLogin from "../../../../components/auth/social_login";
import { getForgot } from "../../../../redux/slice/user-slice";
import { ROUTE_LOGIN } from "../../../../routes";
import { LABLE_EMAIL } from "../../../../utils/constants";

const ForgotPasswordForm = () =>{
    

    const {isForgotLoading,errorForgot,email} = useSelector(state=>state.user);

    const initialState = {
        email:email
    }

    const [formData,setFormData] = useState(initialState);

    const dispatch = useDispatch();

    const handleSubmit = (e) =>{
        e.preventDefault();
        const {email} = formData;
        if(!email)
            return toast.error(LABLE_EMAIL);
        dispatch(getForgot(formData));
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextField sx={{pb:2}} fullWidth value={formData.email} inputProps={{readOnly:isForgotLoading}} onChange={(e)=>setFormData({'email':e.target.value})} label={LABLE_EMAIL} />
            <Button disabled={isForgotLoading} variant="contained" size="large" fullWidth type="submit">Send OTP</Button>
            <Typography align="center" sx={{pt:2,pb:3}}>
                Do you want try to login again? <Link to={ROUTE_LOGIN}>Login</Link>
            </Typography>
            <SocialLogin/>
        </form>
    )
}

export default ForgotPasswordForm;