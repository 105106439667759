import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useSelector } from "react-redux";

const ProductsTable = () =>{

    const {isExpiredProductsLoading,expiredProducts} = useSelector(state=>state.product);

    return(
        <Grid container>
            <Grid item sm={12}>
            <Card sx={{mt:3}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Brand</TableCell>
                            <TableCell>Variant</TableCell>
                            <TableCell>Batch</TableCell>
                            <TableCell>Purchase Price</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Expire</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {isExpiredProductsLoading ?
                        <TableBody>
                            <TableRow>

                            <TableCell>Loadaing</TableCell>
                            </TableRow>
                        </TableBody>
                    : <TableBody>
                    {expiredProducts.map((row,i)=>
                        <TableRow key={i}>
                            <TableCell>{i+1}</TableCell>
                            <TableCell>{row.product.name}</TableCell>
                            <TableCell>{row.product.category}</TableCell>
                            <TableCell>{row.product.brand}</TableCell>
                            <TableCell>{row.variant}</TableCell>
                            <TableCell>{row.batch}</TableCell>
                            <TableCell>{row.purchase_price}</TableCell>
                            <TableCell>{row.price}</TableCell>
                            <TableCell>{row.quantity}</TableCell>
                            <TableCell>{row.location}</TableCell>
                            <TableCell>{row.expire_date}</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>)
                    }
                </TableBody>}
                    
                </Table>
            </TableContainer>
        </Card>
            </Grid>
        </Grid>
    )
}

export default ProductsTable;