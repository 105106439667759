import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isTodaysSalesLoading : true,
    isSalesLoading : true,
    isSalesAdding : false,
    todaysSales : [],
    sales: [],
}


const salesSlice = createSlice({
    initialState,
    name:'sale',
    reducers:{
        getTodaysSales : (state)=>{
            state.isTodaysSalesLoading = true;
        },
        setTodaysSales : (state,action) =>{
            state.todaysSales = action.payload;
            state.isTodaysSalesLoading = false;
        },
        getSales : (state)=>{
            state.isSalesLoading = true;
        },
        setSales : (state,action)=>{
            state.sales = action.payload;
            state.isSalesLoading = false;
        }
    }
})

export const {getTodaysSales,setTodaysSales,getSales,setSales} = salesSlice.actions;
export default salesSlice.reducer;