import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SocialLogin from "../../../../components/auth/social_login";
import { getLogin } from "../../../../redux/slice/user-slice";
import { ROUTE_FORGOT, ROUTE_REGISTER } from "../../../../routes";
import { LABLE_EMAIL, LABLE_PASSWORD } from "../../../../utils/constants";


const LoginForm = () =>{

    const {isLoginLoading,errorLogin,email} = useSelector(state=>state.user);
    const dispatch = useDispatch();

    const initialState = {
        email:email,
        password:'',
    }


    const [formData,setFormData] = useState(initialState);

    const handleChange = (e) =>{
        const {name,value} = e.target;
        setFormData((rest)=>{
            return{
                ...rest,
                [name]:value
            }
        })
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        const {email,password} = formData;
        if(!email)
            return toast.error(LABLE_EMAIL);
        if(!password)
            return toast.error(LABLE_PASSWORD);
        dispatch(getLogin(formData))
    }


    return(
        <form onSubmit={handleSubmit}>
            <TextField error={errorLogin.hasOwnProperty('email')} sx={{pb:2}} helperText={errorLogin.email} fullWidth type="email" name="email" value={formData.email} onChange={handleChange} label={LABLE_EMAIL} inputProps={{readOnly:isLoginLoading}}/>
            <TextField error={errorLogin.hasOwnProperty('password')} helperText={errorLogin.password} fullWidth type="password" name="password" value={formData.password} onChange={handleChange} label={LABLE_PASSWORD} inputProps={{readOnly:isLoginLoading}}/>
            <Typography align="right" sx={{pb:2}} >
                <Link to={ROUTE_FORGOT}>Forgot Password?</Link>
            </Typography>
            <Button disabled={isLoginLoading} type="submit" variant="contained" fullWidth size="large">Login</Button>
            <Typography align="center" sx={{pb:2, pt:2}}>
                Don't have an account? <Link to={ROUTE_REGISTER}>Register</Link>
            </Typography>
            <SocialLogin/>
        </form>
    )
}


export default LoginForm;