import { Typography } from "@mui/material";
import { Box } from "@mui/system";


const Loader = () =>{

    const style = {
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        p:4,
    }
    
    return(
        <Box align="center" sx={{...style}}>
            <Box className="loader" >
                
            </Box>
        </Box>
    )
}

export default Loader;