import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "../components/app-layout";
import ForgotPasswordPage from "../pages/auth/forgot";
import LoginPage from "../pages/auth/login";
import RegisterPage from "../pages/auth/register";
import ResetPage from "../pages/auth/reset";
import VerifyPage from "../pages/auth/verify";
import DashboardPage from "../pages/dashboard";
import DistributersPage from "../pages/distributer/all";
import AddMedicalPage from "../pages/medical/add";
import ProductsAddPage from "../pages/product/add";
import ProductsAll from "../pages/product/all";
import ProductExpiredPage from "../pages/product/expired";
import ProductExpiringPage from "../pages/product/expiring";
import ProductNoticePage from "../pages/product/notice";
import SellProductPage from "../pages/sale/add";
import SalesAllPage from "../pages/sale/all";
import SalesTodayPage from "../pages/sale/today";

export const ROUTE_REGISTER = "/register";
export const ROUTE_LOGIN = "/login";
export const ROUTE_VERIFY = "/verify";
export const ROUTE_FORGOT = "/forgot";
export const ROUTE_RESET = "/reset";

export const ROUTE_DASHBOARD = "/dashboard"
export const ROUTE_SELL = "sell"
export const ROUTE_SALES_TODAY = "sales/today"
export const ROUTE_SALES = "sales"
export const ROUTE_PRODUCTS_NOTICE = "products/notice"
export const ROUTE_PRODUCTS_EXPIRING = "products/expiring"
export const ROUTE_PRODUCTS_EXPIRED = "products/expired"
export const ROUTE_PRODUCTS = "products"
export const ROUTE_ADD_RODUCTS = "products/add"
export const ROUTE_DISTRIBUTERS = "distributers"

//Medicals
export const ROUTE_ADD_MEDICAL = "/medical/add"

const MyRoutes = () =>{

    const PrivateRoute = ({children}) =>{
        const {isAuth} = useSelector(state=>state.user);
        // const isAuth = true;
        console.log('isAuth',isAuth)
        return isAuth ? children : <Navigate to={ROUTE_LOGIN}/>
    }

    const PublicRoute = ({children}) =>{
        const {isAuth} = useSelector(state=>state.user);
        return !isAuth ?  children : <Navigate to={ROUTE_DASHBOARD}/>  ;
    };

    const VerifyAccountRoute = ({children}) =>{
        const {isAuth,email} = useSelector(state=>state.user);
        return !isAuth && email ? children : <Navigate to={ROUTE_DASHBOARD}/> ;
    };

    return(
        <Routes>
           
            <Route path={ROUTE_LOGIN} element={<LoginPage/>}/>
            <Route path={ROUTE_VERIFY} element={<VerifyPage/>}/>
            <Route path={ROUTE_FORGOT} element={<ForgotPasswordPage/>}/>
            <Route path={ROUTE_REGISTER} element={<RegisterPage/>}/>
            <Route path={ROUTE_RESET} element={<ResetPage/>}/>

            <Route path="/" element={<PrivateRoute><AppLayout/></PrivateRoute>}>
                <Route path={ROUTE_DASHBOARD}  element={<DashboardPage/>}/>
                <Route path={ROUTE_SELL}  element={<SellProductPage/>}/>
                <Route path={ROUTE_SALES_TODAY}  element={<SalesTodayPage/>}/>
                <Route path={ROUTE_SALES}  element={<SalesAllPage/>}/>
                <Route path={ROUTE_PRODUCTS_NOTICE}  element={<ProductNoticePage/>}/>
                <Route path={ROUTE_PRODUCTS_EXPIRING}  element={<ProductExpiringPage/>}/>
                <Route path={ROUTE_PRODUCTS_EXPIRED}  element={<ProductExpiredPage/>}/>
                <Route path={ROUTE_PRODUCTS}  element={<ProductsAll/>}/>
                <Route path={ROUTE_ADD_RODUCTS}  element={<ProductsAddPage/>}/>
                <Route path={ROUTE_DISTRIBUTERS}  element={<DistributersPage/>}/>

            </Route>

            <Route path={ROUTE_ADD_MEDICAL}  element={<PrivateRoute><AddMedicalPage/></PrivateRoute>}/>


        </Routes>
    )
}

export default MyRoutes;