import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { LABLE_EMAIL, LABLE_OTP } from "../../../../utils/constants";
import {ROUTE_LOGIN} from '../../../../routes'
import { getResend, getVerify } from "../../../../redux/slice/user-slice";

const VerifyForm = () =>{

    const {email,isVerifyLoading,isResendLoading,errorVerify} = useSelector(state=>state.user);
    const dispatch = useDispatch();

    const initialState = {
        email:email,
        otp:'',
        type:'email'
    }

    const [formData,setFormData] = useState(initialState);

    const handleChange = e =>{
        const {name,value} = e.target;
        setFormData((rest)=>{
            return{
                ...rest,
                [name]:value
            }
        })
    }

    const handleSubmit = e =>{
        e.preventDefault();
        const {otp,email,type} = formData;
        if(!email)
            return toast.error("Oops..! Please referesh the page and try again");
        if(!type)
            return toast.error("Oops..! Please referesh the page and try again");
        if (!otp || otp.length != 6)
            return toast.error("Invalid OTP")
        dispatch(getVerify(formData));
    }

    const resendOtpStyle={
        fontSize:12,
        mb:2,
        cursor:'pointer',
        paddingTop:'5px',
        // width:100,
        float:'right'
    }

    const hanldeResend = () =>{
        if(!isResendLoading){
            dispatch(getResend(formData));
        }
    }

    return(
        <form onSubmit={handleSubmit}>
            <TextField type="email" name="email" value={formData.email} fullWidth sx={{pb:2}} label={LABLE_EMAIL} inputProps={{readOnly:true}} error={errorVerify.hasOwnProperty('email')} helperText={errorVerify.email} />
            <TextField type="number" name="otp" value={formData.otp} onChange={handleChange} fullWidth  label={LABLE_OTP} inputProps={{readOnly:isVerifyLoading}} error={errorVerify.hasOwnProperty('otp')} helperText={errorVerify.otp} />
            <Typography align="right" sx={{...resendOtpStyle}} onClick={hanldeResend}>
               {isResendLoading? 'Sending OTP...': 'Resend OTP'} 
            </Typography>
            <Button type="submit" fullWidth size="large" variant="contained" disabled={isVerifyLoading}>Verify Account</Button>

            <Typography sx={{pt:3}} align="center">
                Back to login page <Link to={ROUTE_LOGIN}>Login</Link>
            </Typography>
        </form>
    )

}

export default VerifyForm;