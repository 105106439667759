import { Button, Card, CardContent, CardHeader, Grid, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { toast } from "react-toastify";
import { LABLE_ADDRESS, LABLE_MOBILE, LABLE_NAME, LABLE_SEARCH_DISTRIBUTERS, TEXT_ADD_DISTRIBUTER } from "../../../../utils/constants";
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from "react-redux";
import {getAddDistributer, setIsOpen} from '../../../../redux/slice/distributer-slice';


const SearchComponent = () =>{

    const initialState = {
        name:'',
        mobile:'',
        address:'',
    };

    const dispatch = useDispatch();
    const {isDistributerAdding,errorAddDistributer,isOpen} = useSelector(state=>state.distributer);
    const [formData,setFormData] = useState(initialState);

    const handleChange = (e) =>{
        const {name,value} = e.target;
        console.log(formData);
        setFormData((rest)=>{
            return{
                ...rest,
                [name]:value
            }
        })
    }

    const handleClose = (e) =>{
        dispatch(setIsOpen(!isOpen));
        setFormData(initialState);
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        const {name,mobile,address} = formData;
        if (!name)
            return toast.error(LABLE_NAME);
        if (!mobile)
            return toast.error(LABLE_NAME);
        if (!address)
            return toast.error(LABLE_ADDRESS);

        let a = dispatch(getAddDistributer(formData));
        console.log('a',a);
    }

    const iconStyle = {
        position : 'absolute',
        top:'5%',
        right:'5%',
        cursor:'pointer',
        p:2
    }

    const style = {
        position:'absolute',
        top:'50%',
        left:'50%',
        minWidth:'30%',
        transform:'translate(-50%,-50%)',
        bgcolor: 'background.paper',
        boxShadow:24,
        p:4,
        borderRadius:5
    }


    return(
        <>
            <Card>
                <CardContent>
                <Grid container>
                    <Grid item sm={11} sx={{pr:3}}>
                        <TextField fullWidth label={LABLE_SEARCH_DISTRIBUTERS} />
                    </Grid>
                    <Grid item sm={1}>
                        <Button variant="contained" onClick={()=>dispatch(setIsOpen(!isOpen))}>Add Distributer</Button>
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
            <Modal
            open={isOpen}
            disableEscapeKeyDown = {true}
            >
                <Card sx={{...style}}>
                    <CardHeader title={TEXT_ADD_DISTRIBUTER}/>
                    <Box sx={{...iconStyle}} onClick={handleClose}>
                        <CloseIcon/>
                    </Box>
                    <CardContent align="center">
                        <form onSubmit={handleSubmit}>
                            <TextField error={errorAddDistributer.hasOwnProperty('name')} helperText={errorAddDistributer.name} inputProps={{readOnly:isDistributerAdding}} type="text" value={formData.name} onChange={handleChange} name="name" fullWidth label={LABLE_NAME} sx={{pb:2}} />
                            <TextField error={errorAddDistributer.hasOwnProperty('mobile')} helperText={errorAddDistributer.mobile} inputProps={{readOnly:isDistributerAdding}} type="number" value={formData.mobile} onChange={handleChange} name="mobile" fullWidth label={LABLE_MOBILE} sx={{pb:2}}/>
                            <TextField error={errorAddDistributer.hasOwnProperty('address')} helperText={errorAddDistributer.address} inputProps={{readOnly:isDistributerAdding}} sx={{height:300}} size="large" type="text" value={formData.address} onChange={handleChange} name="address" fullWidth label={LABLE_ADDRESS} sx={{pb:2}}/>
                            <Button disabled={isDistributerAdding} sx={{width:'50%'}}  size="large" variant="contained" type="submit">{TEXT_ADD_DISTRIBUTER}</Button>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
            </>
        
    )

}

export default SearchComponent;