import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_ADD_MEDICAL, ROUTE_DASHBOARD, ROUTE_VERIFY } from "../../../routes";
import LoginForm from "./components/login-form";


const LoginPage = () =>{

  const {accessVerifyPage,isAuth,user} = useSelector(state=>state.user);
  const navigate = useNavigate();

  const style = {
    position : 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
  }

  const cardStyle={
    p:4,
    boxShadow:4,
    borderRadius:4
  }
console.log(user);
  useEffect(()=>{
    if(accessVerifyPage)
      navigate(ROUTE_VERIFY);
    console.log(user.next)
    if(isAuth)
      if(user.next==='create_medical')
        navigate(ROUTE_ADD_MEDICAL);
      else
        navigate(ROUTE_DASHBOARD);
  },[accessVerifyPage,isAuth])

  return(
    <Grid container sx={{...style}} >
      <Grid item sm={4}></Grid> 
      <Grid item sm={4}>
        <Card sx={{...cardStyle}} >
          <CardHeader sx={{pb:3}} title="Login" subheader="Login into your account to continue..." />
          <LoginForm/>
        </Card>
      </Grid> 
      <Grid item sm={4}></Grid> 
    </Grid>
  )
}

export default LoginPage;