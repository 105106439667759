import { LABLE_SEARCH_PRODUCTS } from "../../../../utils/constants";
import {Grid,CardContent,Card, Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";

const SearchComponent = () =>{

    useEffect(()=>{
        const handleEsc = (e) =>{
            if(e.keyCode==27){
                setOpen(false);
            }
        }
        window.addEventListener('keydown',handleEsc);

        return () =>{
            window.removeEventListener('keydown',handleEsc);
        }
    },[])


    const [open,setOpen] = useState(false);
    const [search,setSearch] = useState();

    const {isProductsLoading,products} = useSelector(state=>state.product);

    const style = {
        position: 'absolute' ,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };

      const handleChange = (e) =>{
        if (!open)
            setOpen(true);
        setSearch(e.target.value);
      }

      const handleRowClick = (e) =>{
        console.log(e);
      }

    return(
        <>
        <Grid container>
            <Grid item sm={12}>
                <Card>
                    <CardContent>
                        <TextField fullWidth label={LABLE_SEARCH_PRODUCTS} onChange={handleChange} value=''/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Modal
        open={open}
        disableEscapeKeyDown = {true}
        >
            <Box sx={{...style,width: 400 }}>
              <TextField fullWidth label={LABLE_SEARCH_PRODUCTS} value={search} focused onChange={handleChange} />
    
              <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sr No</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Brand</TableCell>
                                <TableCell>Variant</TableCell>
                                <TableCell>Batch</TableCell>
                                <TableCell>Purchase Price</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Expire</TableCell>
                            </TableRow>
                        </TableHead>
                        {isProductsLoading ?
                            <TableBody>
                                <TableRow>
    
                                <TableCell>Loadaing</TableCell>
                                </TableRow>
                            </TableBody>
                        : <TableBody>
                        {products.map((row,i)=>
                            <TableRow key={i} onClick={handleRowClick}>
                                <TableCell>{i+1}</TableCell>
                                <TableCell>{row.product.name}</TableCell>
                                <TableCell>{row.product.category}</TableCell>
                                <TableCell>{row.product.brand}</TableCell>
                                <TableCell>{row.variant}</TableCell>
                                <TableCell>{row.batch}</TableCell>
                                <TableCell>{row.purchase_price}</TableCell>
                                <TableCell>{row.price}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                                <TableCell>{row.location}</TableCell>
                                <TableCell>{row.expire_date}</TableCell>
                            </TableRow>)
                        }
                    </TableBody>}
                        
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
        </>
    )

}

export default SearchComponent;