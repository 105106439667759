import { Grid } from "@mui/material";
import DashboardCard from "./components/card";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardProductsStatus } from "../../redux/slice/dashboard-slice";
import { useEffect } from "react";

const DashboardPage = () =>{
    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getDashboardProductsStatus())
    },[])

    const {productStatus} = useSelector((state)=>state.dashboard);
    console.log(productStatus);

    return(
        <Grid container spacing={3}>
        <DashboardCard name="Products" count={productStatus.products} to="/"/>
            <DashboardCard name="Todays Sales" count={productStatus.products} to="/"/>
            <DashboardCard name="Brands" count={productStatus.products} to="/"/>
            <DashboardCard name="Notice" count={productStatus.notice} to="/"/>
            <DashboardCard name="Expired" count={productStatus.expired} to="/"/>
            <DashboardCard name="Expiring" count={productStatus.expiring} to="/"/>
        </Grid>
    )

}

export default DashboardPage;