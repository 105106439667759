import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SocialLogin from "../../../../components/auth/social_login";
import { getResend, getReset } from "../../../../redux/slice/user-slice";
import { ROUTE_LOGIN } from "../../../../routes";
import { LABLE_EMAIL, LABLE_OTP, LABLE_PASSWORD, LABLE_PASSWORD_CONFIRM, TEXT_SWR } from "../../../../utils/constants";

const ResetForm = () =>{

    const {isResetLoading,isResendLoading,errorReset,email:stateEmail} = useSelector(state=>state.user);
    const initialState = {
        email:stateEmail,
        password:'',
        password_confirm:'',
        otp:''
    }

    const [formData,setFormData] = useState(initialState);
    const dispatch = useDispatch();

    const handleSubmit = (e) =>{
        e.preventDefault();
        const {email,password,otp} = formData;
        if(!email)
            return toast.error(TEXT_SWR);
        if(!otp)
            return toast.error(LABLE_OTP);
        if(!password)
            return toast.error(LABLE_PASSWORD);
        dispatch(getReset(formData));
    }
    
    const handleChange = e =>{
        const {name,value} = e.target;
        setFormData((rest)=>{
            return{
                ...rest,
                [name]:value
            }
        })
    }

    const resendOtpStyle={
        fontSize:12,
        mb:2,
        cursor:'pointer',
        paddingTop:'5px',
        // width:100,
        float:'right'
    }

    const hanldeResend = () =>{
        if(!isResendLoading){
            dispatch(getResend(formData));
        }
    }


    return (
        <form onSubmit={handleSubmit}>
            <TextField type="email" name="email" sx={{pb:2}} fullWidth value={formData.email} inputProps={{readOnly:true}} onChange={handleChange} label={LABLE_EMAIL} />
            <TextField type="number" error={errorReset.hasOwnProperty('otp')} helperText={errorReset.otp} name="otp" sx={{pb:2}} fullWidth value={formData.otp} inputProps={{readOnly:isResetLoading}} onChange={handleChange} label={LABLE_OTP} />
            <TextField type="password" error={errorReset.hasOwnProperty('password')} helperText={errorReset.password} name="password" sx={{pb:2}} fullWidth value={formData.password} inputProps={{readOnly:isResetLoading}} onChange={handleChange} label={LABLE_PASSWORD} />
            <TextField type="password" error={errorReset.hasOwnProperty('password_confirm')}  helperText={errorReset.password_confirm} name="password_confirm" fullWidth value={formData.password_confirm} inputProps={{readOnly:isResetLoading}} onChange={handleChange} label={LABLE_PASSWORD_CONFIRM} />
            <Typography align="right" sx={{...resendOtpStyle}} onClick={hanldeResend}>
               {isResendLoading? 'Sending OTP...': 'Resend OTP'} 
            </Typography>
            <Button disabled={isResetLoading} variant="contained" size="large" fullWidth type="submit">RESET PASSWORD</Button>
            <Typography align="center" sx={{pt:2,pb:3}}>
                Do you want try to login again? <Link to={ROUTE_LOGIN}>Login</Link>
            </Typography>
            <SocialLogin/>
        </form>
    )
}

export default ResetForm;