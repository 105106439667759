import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProducts } from "../../../redux/slice/product-slice";
import ProductsTable from "./components/products-table";
import SearchComponent from "./components/search-component";


const ProductsAll = () =>{

    const diaptch = useDispatch();

    useEffect(()=>{
        diaptch(getProducts())
    },[])

    return(
        <>
        <SearchComponent/>
        <ProductsTable/>
        </>
    )
}

export default ProductsAll;