import {REFERESH_TOKEN} from './constants';

export const getRefereshToken = () =>{
    let cookie = document.cookie.split('; ').find(row=>row.startsWith({REFERESH_TOKEN}));
    // console.log('cookie first is',cookie);
    if (!cookie)
        return null;
    cookie = cookie.split('=')[1];
    // console.log('cookie second is',cookie)
    return cookie;
}
