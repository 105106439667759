import { toast } from 'react-toastify';
import {takeLatest,call,put} from 'redux-saga/effects';
import { apiAddDistributer, apiGetDistributers } from '../../http';
import { errorAddDistributer, setAddDistributer, setDistributers } from '../slice/distributer-slice';


function* workGetDistributers(){
    const res = yield call(apiGetDistributers);
    console.log(res);
    yield put(setDistributers(res.results));
}

function* workGetAddDistributer({payload}){
    try{
        const res = yield call(apiAddDistributer,payload)
        if(res.status==200 || res.status==201){
            toast.success("Distributer Added")
            yield put(setAddDistributer(res))
        }
        else{
            yield put(errorAddDistributer(res))
        }
    }
    catch(error){
        console.log(error);
    }

}


export function* getDistributers(){
    yield takeLatest('distributer/getDistributers',workGetDistributers);
}


export function* getAddDistributer(){
    yield takeLatest('distributer/getAddDistributer',workGetAddDistributer);
}