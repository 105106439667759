import {call, put, takeLatest} from 'redux-saga/effects'
import { apiGetSales, apiGetTodaysSales } from '../../http';
import {setSales,setTodaysSales} from '../slice/sale-slice';

function* workGetTodaysSales(){
    const res = yield call(apiGetTodaysSales);
    yield put(setTodaysSales(res.results));
}

function* workGetSales(){
    const res = yield call(apiGetSales);
    yield put(setSales(res.results));
}

export function* getTodaysSales(){
    yield takeLatest('sale/getTodaysSales',workGetTodaysSales);
}

export function* getSales(){
    yield takeLatest('sale/getSales',workGetSales);
}

