import { Card, CardContent, Grid, TextField } from "@mui/material";
import { LABLE_SEARCH_PRODUCTS } from "../../../../utils/constants";


const SearchComponent = () =>{

    return(
        <Grid container>
            <Grid item sm={12}>
                <Card>
                    <CardContent>
                        <TextField fullWidth label={LABLE_SEARCH_PRODUCTS} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

}

export default SearchComponent;