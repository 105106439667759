import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './components/loader/loader';
import { getReferesh } from './redux/slice/user-slice';
import MyRoutes from './routes';


const App = () =>{

  const {isRefereshLoading} = useSelector(state=>state.user);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getReferesh())
  },[]);

  console.log('isRefereshLoading',isRefereshLoading);

  return(
    <>
    {
      isRefereshLoading ? <Loader/> :  
      <MyRoutes/>
    }
    </>
  )
}

export default App;