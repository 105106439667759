import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/constants";
import * as Route from './api_param';
import {getRefereshToken} from '../utils/utils'


const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY2NDEyMjIzLCJpYXQiOjE2NjU1NDgyMjMsImp0aSI6ImU4OGRlOGE4Mjk5ZTQ1Nzk5MmU1ODk3NDFhZjQ0Yzc5IiwidXNlcl9pZCI6MywibmFtZSI6IlVtYWlyIEZhcm9vcXVpIiwibWVkaWNhbCI6IjEiLCJ0eXBlIjoiYWRtaW4iLCJzdGF0dXMiOnRydWUsImxvZ2luX3RpbWVfcmVzdHJpY3Rpb24iOmZhbHNlLCJsb2dpbl9kYXlfcmVzdHJpY3Rpb24iOmZhbHNlLCJsb2dpbl9zdGFydF90aW1lIjoiMDI6MzA6MTUuMDg0NjkwIiwibG9naW5fZW5kX3RpbWUiOiIwMjozMDoxNS4wODQ3MTIiLCJsb2dpbl9zdGFydCI6InN1bmRheSIsImxvZ2luX2VuZCI6InNhdHVyZGF5In0.GnifGSjbzgx1MWiOo0IbSN7VJ9XZ-NbZh4qDck6AJ1g';

console.log(BASE_URL)

const api = axios.create({
    baseURL:BASE_URL,
    withCredentials:true,
    headers:{
        'Content-Type':'application/json',
        'Authorization': token,
    }
});

console.log('getRefereshToken',getRefereshToken());


//Authentication & Authorization
export const apiRegister = data => api.post(Route.ENDPOINT_REGISTER,data);
export const apiVerify = data => api.post(Route.ENDPOINT_VERIFY,data);
export const apiLogin = data => api.post(Route.ENDPOINT_LOGIN,data);
export const apiForgot = data => api.post(Route.ENDPOINT_FORGOT,data);
export const apiResend = data => api.post(Route.ENDPOINT_RESEND,data);
export const apiReset = data => api.post(Route.ENDPOINT_RESET,data);
export const apiReferesh = () => api.get(Route.ENDPOINT_REFERESH);
export const apiLogout = () => api.get(Route.ENDPOINT_LOGOUT);

//Status
export const apiGetProductStatus = () => api.get(Route.ENDPOINT_PRODUCTS_STATUS);

//Products
export const apiGetProducts = () => api.get(Route.ENDPOINT_PRODUCTS);
export const apiGetProductsExpired = () => api.get(Route.ENDPOINT_PRODUCTS_EXPIRED);
export const apiGetProductsExpiring = () => api.get(Route.ENDPOINT_PRODUCTS_EXPIRING);


//Sales
export const apiGetTodaysSales = () => api.get(Route.ENDPOINT_SALES);
export const apiGetSales = () => api.get(Route.ENDPOINT_SALES);

//Distributers
export const apiAddDistributer = data => api.post(Route.ENDPOINT_ADD_DISTRIBUTER,data);
export const apiGetDistributer = () => api.get(Route.ENDPOINT_DISTRIBUTER);
export const apiGetDistributers = () => api.get(Route.ENDPOINT_DISTRIBUTERS);


api.interceptors.response.use((response)=>{
    console.log(response.status)
    response.data.status=response.status;
    return response.data;
},(err)=>{
    // toast.error('dummy')
    console.log(err.response.status)
    err.response.data.status = err.response.status
    return err.response.data;
})
