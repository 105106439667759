import {takeLatest,put,call} from 'redux-saga/effects';
import { apiGetProducts, apiGetProductsExpired, apiGetProductsExpiring } from '../../http';
import {setExpiredProducts, setExpiringProducts, setProducts} from '../slice/product-slice'

function* workGetProducts(){
    const res =  yield call(apiGetProducts);
    console.log(res.results);
    yield put(setProducts(res.results))
}

function* workGetExpiredProducts(){
    const res =  yield call(apiGetProductsExpired);
    console.log(res.results);
    yield put(setExpiredProducts(res.results))
}

function* workGetExpiringProducts(){
    const res =  yield call(apiGetProductsExpiring);
    console.log(res.results);
    yield put(setExpiringProducts(res.results))
}

export function* getProducts(){
    yield takeLatest('product/getProducts',workGetProducts);
}

export function* getExpiredProducts(){
    yield takeLatest('product/getExpiredProducts',workGetExpiredProducts);
}

export function* getExpiringProducts(){
    yield takeLatest('product/getExpiringProducts',workGetExpiringProducts);
}