import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDistributers } from "../../../redux/slice/distributer-slice";
import DistributersTable from "./components/distributers-table";
import SearchComponent from "./components/search-component";


const DistributersPage = () =>{

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getDistributers());
    },[]);

    return(
        <>
        <SearchComponent/>
        <DistributersTable/>
        </>
    )

}

export default DistributersPage;