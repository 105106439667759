import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ForgotPasswordForm from "./components/forgot-form";
import ResetForm from "./components/reset-form";


const ForgotPasswordPage = () =>{

  const {accessResetPage} = useSelector(state=>state.user);

  const style = {
    position : 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
  }

  const cardStyle={
    p:4,
    boxShadow:4,
    borderRadius:4
  }
  



  return(
    <Grid container sx={{...style}} >
      <Grid item sm={4}></Grid> 
      <Grid item sm={4}>
        <Card sx={{...cardStyle}} >
          <CardHeader sx={{pb:3}} title={`${!accessResetPage ? 'Forgot Password' : 'Reset Password'}`} subheader={`${!accessResetPage ? 'We will send an OTP to reset your password' : 'Verify your account and set a new one'}`}/>
          {
            accessResetPage ?  <ResetForm/> : <ForgotPasswordForm/> 
          }
          
        </Card>
      </Grid> 
      <Grid item sm={4}></Grid> 
    </Grid>
  )
}

export default ForgotPasswordPage;