import {takeLatest,put,call} from 'redux-saga/effects';
import {apiGetProductStatus} from '../../http/index';
import { setDashboardProductsStatus } from '../slice/dashboard-slice';

function* workGetDashboardProductsStatus(){
    const resp = yield call(apiGetProductStatus);
    yield put(setDashboardProductsStatus(resp))
    console.log(resp);
}

export function* getDashboardProductsStatus(){
    yield takeLatest('dashboard/getDashboardProductsStatus',workGetDashboardProductsStatus);
}

