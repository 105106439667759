import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ProductsTable from "./components/products-table";
import { getTodaysSales } from "../../../redux/slice/sale-slice";
import SearchComponent from "./components/search-component";


const SalesTodayPage = () =>{

    const diaptch = useDispatch();

    useEffect(()=>{
        diaptch(getTodaysSales())
    },[])

    return(
        <>
        <SearchComponent/>
        <ProductsTable/>
        </>
    )
}

export default SalesTodayPage;