import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";


const DashboardCard = ({name,count,to}) =>{

    return (
        <Grid item sm={2}>
            <Card>
                <CardContent>
                    <Typography variant="h3">{count}</Typography>
                    <Typography variant="h5">{name  }</Typography>
                </CardContent>
                <CardActionArea>
                    <Link to={to} align="center">
                        <Typography variant="h6">
                            More
                        </Typography>
                    </Link>
                </CardActionArea>
            </Card>
        </Grid>
    )

}

export default DashboardCard;