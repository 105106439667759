import { toast } from 'react-toastify';
import { takeLatest, put, call } from 'redux-saga/effects';
import { apiForgot, apiLogin, apiReferesh, apiRegister, apiResend, apiReset, apiVerify } from '../../http';
import { failedForgot, failedLogin, failedRegsiter, failedResend, failedReset, failedVerify, setForgot, setLogin, setLogout, setRegsiter, setResend, setReset, setVerify } from '../slice/user-slice';


function* workGetLogin({ payload }) {

    try {
        const res = yield call(apiLogin, payload);
        if (res.success) {
            toast.success(res.message);
            yield put(setLogin(res.data))
        }
        else {
            yield put(failedLogin(res.error))
        }
    }
    catch (e) {
        yield put(failedLogin())
    }

}

function* workGetVerify({ payload }) {

    try {
        const res = yield call(apiVerify, payload);
        if (res.success) {
            toast.success(res.message);
            yield put(setVerify(res.data))
        }
        else {
            console.log(res)
            yield put(failedVerify(res.error))
        }
    }
    catch (e) {
        yield put(failedVerify())
    }
}

function* workGetResend({ payload }) {

    try {
        const res = yield call(apiResend, payload);
        if (res.success) {
            toast.success(res.message);
            yield put(setResend(res.data))
        }
        else {
            console.log(res)
            yield put(failedResend(res.error))
        }
    }
    catch (e) {
        yield put(failedResend())
    }
}


function* workGetRegister({ payload }) {
    try {
        const res = yield call(apiRegister, payload);
        console.log(res);
        if (res.success) {
            toast.success(res.message);
            yield put(setRegsiter(payload.email))
        }
        else {
            toast.error(res.message)
            yield put(failedRegsiter(res.error))
        }
    }
    catch (e) {
        yield put(failedRegsiter())
    }
}

function* workGetForgot({ payload }) {
    try {
        const res = yield call(apiForgot, payload);
        if (res.success) {
            toast.success(res.message);
            yield put(setForgot(payload.email));
        }
        else {
            toast.error(res.message)
            yield put(failedForgot())
        }
    }
    catch (e) {
        yield put(failedForgot())
    }
}

function* workGetReferesh() {
    try {
        const res = yield call(apiReferesh);
        console.log('workGetReferesh')
        if (res.success) {
            toast.success(res.message);
            yield put(setLogin(res.data));
        }
        else {
            yield put(failedLogin({'email':''}));
            toast.error()
        }
    }
    catch (e) {
        console.log(e);
    }
}

function* workGetReset({ payload }) {
    try {
        const res = yield call(apiReset, payload);
        if (res.success) {
            toast.success(res.message);
            yield put(setReset());
        } else {
            if (res.hasOwnProperty('error'))
                yield put(failedReset(res.error))
            else
                yield put(failedReset());
        }
    }
    catch (e) {
        yield put(failedReset());
    }
}

function* workGetLogout() {
    try {
        const res = yield call(apiReferesh);
        toast.success(res.message);
        yield put(setLogout());
    }
    catch (e) {
        yield put(setLogout());
    }
}

export function* getReferesh() {
    yield takeLatest('user/getReferesh', workGetReferesh);
}

export function* getForgot() {
    yield takeLatest('user/getForgot', workGetForgot);
}

export function* getReset() {
    yield takeLatest('user/getReset', workGetReset);
}

export function* getLogin() {
    yield takeLatest('user/getLogin', workGetLogin);
}

export function* getRegister() {
    yield takeLatest('user/getRegister', workGetRegister);
}

export function* getVerify() {
    yield takeLatest('user/getVerify', workGetVerify);
}

export function* getResend() {
    yield takeLatest('user/getResend', workGetResend);
}

export function* getLogout() {
    yield takeLatest('user/getLogout', workGetLogout);
}