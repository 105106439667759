import { configureStore } from "@reduxjs/toolkit";
import userSlice from '../slice/user-slice';
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from '../saga/root-saga'
import dashboardSlice from "../slice/dashboard-slice";
import productSlice from "../slice/product-slice";
import saleSlice from "../slice/sale-slice";
import distributerSlice from "../slice/distributer-slice";

const saga = createSagaMiddleware();

const store = configureStore({
    reducer:{
        user:userSlice,
        product:productSlice,
        dashboard:dashboardSlice,
        sale:saleSlice,
        distributer:distributerSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saga),
});

saga.run(rootSaga); 

export default store;