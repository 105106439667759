import { Card, CardHeader, Grid, Typography } from "@mui/material";

const ResetPage = () =>{

  const style = {
    position : 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
  }

  const cardStyle={
    p:4,
    boxShadow:4,
    borderRadius:4
  }

  return(
    <Grid container sx={{...style}}>
      <Grid item sm={4}></Grid> 
      <Grid item sm={4}>
        <Card sx={{...cardStyle}} >
          <CardHeader sx={{pb:3}} title="Login" subheader="Login into your account to continue..." />
          {/* <LoginForm/> */}
        </Card>
      </Grid> 
      <Grid item sm={4}></Grid> 
    </Grid>
  )
}

export default ResetPage;