import { Grid } from "@mui/material";

const SocialLogin = () =>{
    return(
        <Grid container align="center">
            <Grid item sm={6}>
                Facebook
            </Grid>
            <Grid item sm={6}>
                Google
            </Grid>
        </Grid>
    )
}

export default SocialLogin;