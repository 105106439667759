import { createSlice } from "@reduxjs/toolkit";

const errors = {
    errorLogin: {},
    errorRegister: {},
    errorForgot: {},
    errorReset: {},
    errorResend: {},
    errorVerify: {},
}

const custom = {
    accessVerifyPage: false,
    accessResetPage: false,
}

const loading = {
    isRefereshLoading: true,
    isLoginLoading: false,
    isRegisterLoading: false,
    isForgotLoading: false,
    isResetLoading: false,
    isVerifyLoading: false,
    isResendLoading: false,
    isLogoutLoading: false,
}

const initialState = {
    ...errors,
    ...custom,
    ...loading,
    isAuth: false,
    user: {},
    email: ''
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getReferesh: (state) => {
            state.isRefereshLoading = true;
        },
        getLogin: (state, action) => {
            state.isLoginLoading = true;
            state.errorLogin = {}
            state.email = action.payload.email;
        },
        setLogin: (state, action) => {
            state.isLoginLoading = false;
            state.isRefereshLoading = false;
            state.user = action.payload;
            state.isAuth = true;
        },
        failedLogin: (state, action) => {
            state.isLoginLoading = false;
            state.isRefereshLoading = false;
            if (action.payload)
                state.errorLogin = action.payload;
                if (action.payload.email == 'Email Verification Pending') {
                    state.accessVerifyPage = true;
                    state.errorLogin = {}
                }
        },
        getRegister: (state) => {
            state.isRegisterLoading = true;
            state.errorRegister = {}
        },
        setRegsiter: (state, action) => {
            state.isRegisterLoading = false;
            state.accessVerifyPage = true;
            state.email = action.payload;
            state.errorRegister = {};
        },
        failedRegsiter: (state, action) => {
            state.isRegisterLoading = false
            if (action.payload)
                state.errorRegister = action.payload;
        },
        getForgot: (state) => {
            state.isForgotLoading = true;
            state.errorForgot = {};
        },
        setForgot: (state, action) => {
            state.isForgotLoading = false;
            state.email = action.payload;
            state.accessResetPage = true;
        },
        failedForgot: (state, action) => {
            state.isForgotLoading = false;
            if (action.payload)
                state.errorForgot = action.payload;
        },
        getReset: (state) => {
            state.isResetLoading = true;
            state.errorForgot = {};
        },
        setReset: (state, action) => {
            state.isResetLoading = false;
            state.errorReset = {};
            state.accessResetPage = false;
        },
        failedReset: (state, action) => {
            state.isResetLoading = false;
            if (action.payload)
                state.errorReset = action.payload;
        },
        getVerify: (state) => {
            state.isVerifyLoading = true;
            state.errorVerify = {}
        },
        setVerify: (state, action) => {
            state.isVerifyLoading = false;
            state.accessVerifyPage = false;
        },
        failedVerify: (state, action) => {
            state.isVerifyLoading = false;
            state.errorVerify = action.payload;
        },
        getResend: (state) => {
            state.isResendLoading = true;
            state.errorResend = {}
        },
        setResend: (state, action) => {
            state.isResendLoading = false;
        },
        failedResend: (state, action) => {
            state.isResendLoading = false;
            state.errorResend = action.payload;
        },
        getLogout: (state) => {
            state.isLogoutLoading = true;
        },
        setLogout: (state) => {
            state.isLogoutLoading = false;
        },

    }
});

// const get = {getReferesh,getLogin,getRegister,getForgot,getReset,getVerify} = userSlice.actions;
// const set = {setLogin,setRegsiter,setForgot,setReset,setVerify} = userSlice.actions;
// const failed = {failedLogin,failedRegsiter,failedForgot,failedReset,failedVerify} = userSlice.actions;

// export const {} = userSlice.actions;

export const {
    getReferesh,
    getLogin, setLogin, failedLogin,
    getRegister, setRegsiter, failedRegsiter,
    getForgot, setForgot, failedForgot,
    getReset, setReset, failedReset,
    getVerify, setVerify, failedVerify,
    getResend, setResend, failedResend,
    getLogout, setLogout,
} = userSlice.actions;

export default userSlice.reducer;