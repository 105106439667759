import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useSelector } from "react-redux";

const DistributersTable = () =>{

    const {isDistributersLoading,distributers} = useSelector(state=>state.distributer);

    return(
        <Grid container>
            <Grid item sm={12}>
            <Card sx={{mt:3}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {isDistributersLoading ?
                        <TableBody>
                            <TableRow>

                            <TableCell>Loadaing</TableCell>
                            </TableRow>
                        </TableBody>
                    : <TableBody>
                    {distributers.map((row,i)=>
                        <TableRow key={i}>
                            <TableCell>{i+1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>)
                    }
                </TableBody>}
                    
                </Table>
            </TableContainer>
        </Card>
            </Grid>
        </Grid>
    )
}

export default DistributersTable;