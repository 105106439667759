import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SocialLogin from "../../../../components/auth/social_login";
import { getRegister } from "../../../../redux/slice/user-slice";
import { ROUTE_LOGIN } from "../../../../routes";
import { LABLE_EMAIL, LABLE_NAME, LABLE_PASSWORD } from "../../../../utils/constants";


const RegisterForm = () =>{

    const {isRegisterLoading,errorRegister} = useSelector(state=>state.user);
    const dispatch = useDispatch();

    const initialState = {
        name:'',
        email:'',
        password:'',
    }

    const [formData,setFormData] = useState(initialState);

    const handleChange = e =>{
        const {name,value} = e.target;
        setFormData((rest)=>{
            return{
                ...rest,
                [name]:value
            }    
        })
    }

    const handleSubmit = e =>{
        e.preventDefault();
        const {name,email,password} = formData;
        if(!name)
            return toast.error(LABLE_NAME);
        if(!email)
            return toast.error(LABLE_EMAIL);
        if(!password)
            return toast.error(LABLE_PASSWORD);
        dispatch(getRegister(formData))
    }

    return(
        <form onSubmit={handleSubmit}>
            <TextField error={errorRegister.hasOwnProperty('name')} helperText={errorRegister.name} fullWidth type="text" name="name" value={formData.name} onChange={handleChange} inputProps={{readOnly:isRegisterLoading}} label={LABLE_NAME} sx={{pb:2}}/>
            <TextField error={errorRegister.hasOwnProperty('email')} helperText={errorRegister.email} fullWidth type="email" name="email" value={formData.email} onChange={handleChange} inputProps={{readOnly:isRegisterLoading}} label={LABLE_EMAIL} sx={{pb:2}}/>
            <TextField error={errorRegister.hasOwnProperty('password')} helperText={errorRegister.password} fullWidth type="password" name="password" value={formData.password} onChange={handleChange} inputProps={{readOnly:isRegisterLoading}} label={LABLE_PASSWORD} sx={{pb:2}}/>
            <Button disabled={isRegisterLoading} fullWidth type="submit" variant="contained" size="large">Register</Button>
            <Typography align="center" sx={{pt:3,pb:3}}>
                Already have an account? <Link to={ROUTE_LOGIN}>Login</Link>
            </Typography>
            <SocialLogin/>
        </form>
    );
}

export default RegisterForm;