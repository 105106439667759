import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import VerifyForm from "../verify/components/verify-form";
import {ROUTE_LOGIN} from '../../../routes';


const VerifyPage = () =>{

  const style = {
    position : 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
  }

  const {accessVerifyPage} = useSelector(state=>state.user);
  const navigate = useNavigate();

  const cardStyle={
    p:4,
    boxShadow:4,
    borderRadius:4
  }

  useEffect(()=>{
    if(!accessVerifyPage)
      navigate(ROUTE_LOGIN);
  },[accessVerifyPage])

  return(
    <Grid container sx={{...style}}>
      <Grid item sm={4}></Grid> 
      <Grid item sm={4}>
        <Card sx={{...cardStyle}} >
          <CardHeader sx={{pb:3}} title="Verify Account" subheader="Enter OTP to verify your account...." />
          <VerifyForm/>
        </Card>
      </Grid> 
      <Grid item sm={4}></Grid> 
    </Grid>
  )
}

export default VerifyPage;