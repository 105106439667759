

const AddMedicalPage = () =>{

    return(
        <h1>ddmedical page</h1>
    );

}

export default AddMedicalPage;